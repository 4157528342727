import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProductPageTemplate from "../../components/productPageTemplate"
import Img from "gatsby-image"
import Narrow from "../../components/narrow"

const GostaPage = () => {
  const query = useStaticQuery(graphql`
    query gostaQuery {
      mainImage: file(relativePath: { eq: "wash-top.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      secondImage: file(relativePath: { eq: "IMG_3923.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      thirdImage: file(relativePath: { eq: "IMG_0258.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      black: file(relativePath: { eq: "Gosta_svart.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      cover: file(relativePath: { eq: "Gosta_overdrag.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const product = {
    name: "Gösta",
    mainImage: query.mainImage.childImageSharp.fluid,
    // secondImage: query.secondImage.childImageSharp.fluid,
    // thirdImage: query.thirdImage.childImageSharp.fluid,
    // secondImageNarrow: true,
    description:
      "Denna modell av cykeltvätt kan får med kompressor för snabbtorkning och luftpåfyllning. Fungerar med korgar, väskor och de flesta barnstolar samt elcyklar och mindre mopeder. Klarar tvättning i temperaturer ner till minus 3 grader. Kan köpas med kapell.",
    pdfLink: "/cykeltvätten.se_Gösta_produktblad.pdf",
    facts: (
      <ul>
        <li>Tillverkad i rostfritt 18,8 stål</li>
        <li>90 l integrerad tank</li>
        <li>1 % tvättmedelsblandning</li>
        <li>5 kg vattentryck (alla förekommande cykellager klarar ca 6 kg)</li>
        <li>Högeffektiva borstar</li>
        <li>Rullhjul för förflyttning</li>
        <li>Spillvatten återfiltreras</li>
        <li>Kontrollerbart utsläpp</li>
        <li>
          Plastdetaljer tillverkade i slagtålig plast samt återvinningsvänlig
        </li>
      </ul>
    ),
    specs: (
      <ul>
        <li>Vattentank volym: 120 liter</li>
        <li>Elförbrukning: 1 200-3 100 watt</li>
        <li>Tvättkapacitet/tank: 110 tvättar</li>
        <li>Tvättkapacitet/timme: 30 tvättar</li>
        <li>Förbrukning/tvätt: 1-2 deciliter</li>
        <li>Borstar: 2 st</li>
        <li>Munstycken: 6 st</li>
        <li>TILLVAL: Betalning: Kortläsare, Swish</li>
        <li>
          MÅTT [MM]: Längd: 1 400 (3 100 inkl. ramp) Bredd: 1 100 Höjd: 1 150
        </li>
      </ul>
    ),
  }

  return (
    <ProductPageTemplate
      product={product}
      additionalContent={
        <div>
          <Narrow>
            <div style={{ height: 32 }} />
            <p className="centered">
              Finns även i svart, där hela skalet består av återvunnen plast
            </p>
            <Img fluid={query.black.childImageSharp.fluid} alt="" />
            <div style={{ height: 32 }} />
            <p className="centered">Bild på kapell</p>
            <Img fluid={query.cover.childImageSharp.fluid} alt="" />
          </Narrow>
        </div>
      }
    />
  )
}
export default GostaPage
